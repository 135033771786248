<template>
	<div class="pageContainer">
		<a-row class="financialManagement">
			<a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
				<a-form-model-item ref="time" prop="time">
					<a-range-picker v-model="formInline.time" />
				</a-form-model-item>
				<a-form-model-item ref="taxState" prop="taxState">
					<a-select v-model="formInline.taxState" style="width: 150px" placeholder="是否开发票" allowClear>
						<a-select-option value="0">
							未开票
						</a-select-option>
						<a-select-option value="1">
							已开票
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="payType" prop="payType" v-if="this.activeKey == 1 || this.activeKey == 3">
					<a-select v-model="formInline.payType" style="width: 150px" placeholder="支付方式" allowClear>
						<a-select-option value="1">
							支付宝支付
						</a-select-option>
						<a-select-option value="2">
							微信支付
						</a-select-option>
						<a-select-option value="3">
							会员免费
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="type" prop="type" v-if="this.activeKey == 2 || this.activeKey == 4">
					<a-select v-model="formInline.type" style="width: 150px" placeholder="支付方式" allowClear>
						<a-select-option value="1">
							微信支付
						</a-select-option>
						<a-select-option value="2">
							支付宝支付
						</a-select-option>
						<a-select-option value="3" v-if="this.activeKey == 2">
							会员免费
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-tooltip title="当前选择的订单类型下所有订单一次性开票">
					<a-button class="button" @click="makeOutAnInvoice">全部开票</a-button>
				</a-tooltip>
				<a-button class="button" @click="invoiceRecord">发票记录</a-button>
				<a-form-model-item class="search">
					<a-dropdown-button type="primary" html-type="submit">
						<a-icon type="search" />
						查询
						<a-menu slot="overlay">
							<a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</a-form-model-item>
			</a-form-model>
			<a-row class="btnRow">
				<a-button type="primary" :disabled="selectedRowKeys.length == 0" @click="addAcctTax">开票</a-button>
			</a-row>
			<a-tabs :activeKey="activeKey" @change="tabsChange">
				<a-tab-pane :key="1" tab="广告检测">
					<a-table :row-selection="rowSelection" rowKey="tradeId" :loading="loading" :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }"></a-table>
				</a-tab-pane>
				<a-tab-pane :key="2" tab="合同检测">
					<a-table :row-selection="rowSelection" rowKey="contractTrade" :loading="loading" :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }"></a-table>
				</a-tab-pane>
				<a-tab-pane :key="3" tab="会员订单">
					<a-table :row-selection="rowSelection" rowKey="flowId" :loading="loading" :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }"></a-table>
				</a-tab-pane>
				<a-tab-pane :key="4" tab="活动与咨询">
					<a-table :row-selection="rowSelection" rowKey="orderId" :loading="loading" :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }"></a-table>
				</a-tab-pane>
			</a-tabs>
		</a-row>
	</div>
</template>
<script>
export default {
	data() {
		const _this = this;
		return {
			activeKey: 1,
			loading: true,
			formInline: {
				time: [],
				taxState: undefined,
				payType: undefined,
				type: undefined,
			},
			columns: [
				{
					title: '订单编号',
					dataIndex: 'tradeId',
					key: 'tradeId',
					customRender(text, record) {
						if (_this.activeKey == 1) {
							return text;
						} else if (_this.activeKey == 2) {
							return record.contractTrade;
						} else if (_this.activeKey == 4) {
							return record.orderId;
						} else if (_this.activeKey == 3) {
							return record.flowId;
						}
					},
				},
				{
					title: '创建时间',
					dataIndex: 'addTime',
					key: 'addTime',
					customRender(text) {
						return _this.$moment(text).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				// {
				//     title: "支付状态",
				//     dataIndex: "payState",
				//     key: "payState",
				//     customRender(text, record) {
				//         if (_this.activeKey == 1) {
				//             return text == 0 ? "支付中" : text == 1 ? "支付成功" : text == 2 ? "支付失败" : text == 3 ? "已退款" : text == 4 ? "部分退款" : "--";
				//         } else if (_this.activeKey == 2) {
				//             return record.state == 0 ? "未支付" : record.state == 1 ? "支付成功" : record.state == 2 ? "支付中" : record.state == 3 ? "已退款" : text == 4 ? "部分退款" : "--";
				//         }

				//     },
				// },
				{
					title: '支付方式',
					dataIndex: 'payType',
					key: 'payType',
					customRender(text, record) {
						if (_this.activeKey == 1) {
							return text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : text == 3 ? '会员免费' : '--';
						} else if (_this.activeKey == 2) {
							return record.type == 1 ? '微信支付' : record.type == 2 ? '支付宝支付' : record.type == 3 ? '会员免费' : '--';
						} else if (_this.activeKey == 4) {
							return record.paymentType == 1 ? '微信支付' : record.paymentType == 2 ? '支付宝支付' : record.paymentType == 3 ? '会员免费' : '--';
						} else if (_this.activeKey == 3) {
							return text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : text == 3 ? '余额' : '--';
						}
					},
				},
				// {
				//     title: "发票状态",
				//     dataIndex: "taxState",
				//     key: "taxState",
				//     customRender(text) {
				//         return text == 1 ? "已开发票" : "未开发票";
				//     },
				// },
				{
					title: '实付金额',
					dataIndex: 'payAmount',
					key: 'payAmount',
					width: 100,
					customRender(text, record) {
						if (_this.activeKey == 1) {
							return text ? text + '元' : 0;
						} else if (_this.activeKey == 2 || _this.activeKey == 4) {
							return record.amount ? (record.amount / 1000).toFixed(2) + '元' : 0;
						} else if (_this.activeKey == 3) {
							return record.totalAmount ? (record.totalAmount / 1000).toFixed(2) + '元' : 0;
						}
					},
				},
			],
			data: [],
			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			pageSize: 10,
			userInfo: {},
			tableHeight: 450,
			selectedRowKeys: [],
		};
	},
	computed: {
		rowSelection() {
			const _this = this;
			return {
				selectedRowKeys: _this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					_this.selectedRowKeys = selectedRowKeys;
				},
			};
		},
	},
	created() {
		this.userInfo = this.$store.getters.userInfo;
		this.handleSubmit('placeholder', 0, 10);

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 390;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 500;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 530;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 650;
		}
	},
	methods: {
		tabsChange(e) {
			(this.formInline = {
				time: [],
				taxState: undefined,
				payType: undefined,
				type: undefined,
			}),
				(this.activeKey = e);
			this.data = [];
			this.loading = true;
			this.pagination.current = 0;
			this.pagination.total = 0;
			this.selectedRowKeys = [];
			this.handleSubmit('placeholder', 0, 10);
		},
		tableChange(e) {
			this.handleSubmit('placeholder', e.current - 1, e.pageSize);
			this.pagination.current = e.current;
		},
		handleSubmit(e, pageNum, pageSize) {
			let that = this;
			let time = this.formInline.time;
			let params = {
				pageNum: pageNum || pageNum == 0 ? pageNum : 0,
				pageSize: pageSize ? pageSize : that.pageSize ? that.pageSize : 10,
			};
			if (time && time.length > 0) {
				let minAddTime = that.$moment(time[0]).format('YYYY-MM-DD') + 'T00:00:00';
				let maxAddTime = that.$moment(time[1]).format('YYYY-MM-DD') + 'T23:59:59';
				params.minAddTime = minAddTime;
				params.maxAddTime = maxAddTime;
			}
			if (this.formInline.payType) {
				params.payType = Number(this.formInline.payType);
			}
			if (this.formInline.type && this.activeKey == 2) {
				params.type = Number(this.formInline.type);
			}
			if (this.formInline.type && this.activeKey == 4) {
				params.paymentType = Number(this.formInline.type);
			}
			if (this.formInline.taxState) {
				params.taxState = Number(this.formInline.taxState);
			}
			let url = null;
			if (this.activeKey == 1) {
				url = '/monitortrade/allTradeList';
				params.payState = 1;
				params.buyUserCode = this.userInfo.userCode;
				params.state = 1;
			} else if (this.activeKey == 2) {
				url = '/resource/contracttrade/queryPageList';
				params.state = 1;
				params.userCode = this.userInfo.userCode;
				params.status = 1;
			} else if (this.activeKey == 4) {
				url = '/resource/trainorder/queryPageList';
				params.state = 1;
				params.userCode = this.userInfo.userCode;
			} else if (this.activeKey == 3) {
				url = '/viporder/queryPageList';
				params.state = 1;
				params.userCode = this.userInfo.userCode;
			}
			if (url) {
				this.$http
					.post(url, params)
					.then(res => {
						that.loading = false;
						if (res.rescode == 200) {
							that.data = res.data.dataList;
							that.pagination.total = res.data.totalCount;
						} else {
							that.$message.warning(res.msg);
						}
					})
					.catch(error => {
						that.loading = false;
						console.log(error);
					});
			}
		},
		reset() {
			this.$refs.ruleForm.resetFields();
			this.handleSubmit('placeholder', 0, this.pageSize);
			this.pagination.current = 0;
		},
		addAcctTax() {
			this.$router.push({
				name: 'MakeOutAnInvoice',
				query: { type: this.activeKey, idList: this.selectedRowKeys },
			});
		},
		makeOutAnInvoice() {
			this.$router.push({
				name: 'MakeOutAnInvoice',
				query: { type: this.activeKey },
			});
		},
		invoiceRecord() {
			this.$router.push({
				name: 'InvoiceRecord',
				query: {},
			});
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.financialManagement {
		padding: 16px;
		height: 100%;
		background: #fff;
		.btnRow {
			margin-top: 8px;
		}
		.button {
			margin-top: 3px;
			margin-right: 20px;
			color: #999999;
		}
		.search {
			float: right;
		}
	}
}
</style>
